import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Box, Typography, CircularProgress } from '@mui/material';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import 'chartjs-plugin-datalabels';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import axios from 'axios';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ChartDataLabels);

const KeywordRanksLineChart = ({ projectId }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (projectId) {
      axios.get(`https://seo.digitalhive.in/seo/wp-json/custom/v1/keywords/${projectId}`)
        .then(response => {
          const keywords = response.data;
          const chartData = {
            labels: keywords.map(item => item.keyword),
            datasets: [
              {
                label: 'Keyword Rank',
                data: keywords.map(item => item.rankVal),
                borderColor: '#1dd1a1',
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                pointBackgroundColor: '#1dd1a1',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: '#1dd1a1',
                fill: true,
                tension: 0.4,
                datalabels: {
                  align: 'end',
                  anchor: 'end',
                },
              },
            ],
          };
          setData(chartData);
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching latest keyword ranks:', error);
          setLoading(false);
        });
    }
  }, [projectId]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: 'Latest Keyword Ranks',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.dataset.label}: ${context.raw}`;
          },
        },
      },
      datalabels: {
        color: '#1dd1a1',
        formatter: function (value) {
          return value;
        },
      },
    },
    scales: {
      y: {
        reverse: true,
        beginAtZero: false,
        title: {
          display: true,
          text: 'Rank',
        },
        ticks: {
          stepSize: 10,
        },
        grid: {
          display: false,
        },
      },
      x: {
        title: {
          display: false,
        },
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <Box sx={{ p: 5 }}>
      <Typography variant="h6" component="div" gutterBottom>
        Keywords Latest Rank
      </Typography>
      {loading ? <CircularProgress /> : <Line data={data} options={options} />}
    </Box>
  );
};

export default KeywordRanksLineChart;
