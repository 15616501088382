import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  TablePagination,
} from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";

const ReportList = ({ project, projectId }) => {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0); // current page
  const [rowsPerPage, setRowsPerPage] = useState(5); // rows per page
  // Extract project_name from project object using destructuring
  const { acf_fields: { project_name } = {} } = project || {};

  // Function to convert project name to slug (if needed)
  const slugify = (text) => {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, ""); // Trim - from end of text
  };

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const token = localStorage.getItem("token");
        const slug = slugify(project_name); // Ensure the project_name is slugified

        const response = await axios.get(
          `https://seo.digitalhive.in/seo/wp-json/custom/v1/reports/${slug}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setReports(response.data);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
        toast.error("Failed to fetch reports");
      }
    };

    if (project_name) {
      fetchReports();
    } else {
      setLoading(false);
      setError(new Error("Project name is missing"));
    }
  }, [project_name]);

  const extractDateFromFilename = (filename) => {
    // Regex to match the date patterns in the filenames
    const regex =
      /(\d{2}_\d{2})report|(\d{2}_\d{2}_\d{4})_to_(\d{2}_\d{2}_\d{4})/;
    const match = filename.match(regex);

    if (match) {
      if (match[1]) {
        // Handle the `dd_mmreport` pattern
        return match[1].replace("_", "/");
      } else if (match[2] && match[3]) {
        // Handle the `dd_mm_yyyy_to_dd_mm_yyyy` pattern
        return `${match[2].replace(/_/g, "/")} to ${match[3].replace(
          /_/g,
          "/"
        )}`;
      }
    }

    return "Unknown Date";
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to zero when rows per page changes
  };
  if (loading) return <Typography>Loading reports...</Typography>;
  if (error)
    return <Typography>Error loading reports: {error.message}</Typography>;

  return (
    <Box>
    <Typography variant="h6" gutterBottom>
      Generated reports for {project_name || "this project"}
    </Typography>
    {reports.length === 0 ? (
      <Typography>No reports found for this project.</Typography>
    ) : (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>File Name</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reports.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((report, index) => {
              const fileName = report.split('/').pop();
              const date = extractDateFromFilename(fileName); // Example to extract date
              return (
                <TableRow key={index}>
                  <TableCell>{fileName}</TableCell>
                  <TableCell>{date}</TableCell>
                  <TableCell>
                    <a
                      href={report}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Report
                    </a>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={reports.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    )}
  </Box>
  );
};

export default ReportList;
