// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(225, 29, 72)',
    },
  },
  typography: {
    fontFamily: 'Helvetica, Arial, sans-serif',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: 'Helvetica, Arial, sans-serif',
        },
      },
    },
  },
});

export default theme;
