import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, TextField, Button, DialogActions, MenuItem } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';

const initialData = {
  url: '',
  username: '',
  password: '',
  email_id: '',
  domain_authority: '',
  spam_score: '',
  date_added: '',
  activity_type: '',
  title: '',
  
};

const CredentialsForm = ({ website, category, projectId, onClose }) => {
  const [formData, setFormData] = useState(website || { ...initialData, activity_type: category, project_id: projectId, date_added: new Date().toISOString().split('T')[0] });

  useEffect(() => {
    if (website) {
      setFormData(website);
    }
  }, [website]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      const payload = {
        ...formData,
        project_id: projectId // Ensure project_id is included in the payload
      };
      if (website) {
        await axios.put(`https://seo.digitalhive.in/seo/wp-json/custom/v1/activity-links/${website.id}`, payload);
        toast.success('Activity link updated successfully!');
      } else {
        await axios.post('https://seo.digitalhive.in/seo/wp-json/custom/v1/activity-links', payload);
        toast.success('Activity link added successfully!');
      }
      onClose();
    } catch (error) {
      toast.error('Error saving data');
      console.error('Error saving data:', error);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" gutterBottom>
        {website ? 'Edit Credential' : 'Add New Credential'}
      </Typography>
      <Grid container spacing={2}>
      <Grid item xs={12}>
          <TextField
            label="URL"
            name="url"
            fullWidth
            variant="outlined"
            value={formData.url}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Username"
            name="username"
            fullWidth
            variant="outlined"
            value={formData.username}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Password"
            name="password"
            fullWidth
            variant="outlined"
            value={formData.password}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Email ID"
            name="email_id"
            fullWidth
            variant="outlined"
            value={formData.email_id}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Domain Authority"
            name="domain_authority"
            type="number"
            fullWidth
            variant="outlined"
            value={formData.domain_authority}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Spam Score"
            name="spam_score"
            type="number"
            fullWidth
            variant="outlined"
            value={formData.spam_score}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Date Added"
            name="date_added"
            type="date"
            fullWidth
            variant="outlined"
            value={formData.date_added}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Activity Type"
            name="activity_type"
            select
            fullWidth
            variant="outlined"
            value={formData.activity_type}
            onChange={handleChange}
          >
            <MenuItem value="Article-Submissions">Article-Submissions</MenuItem>
            <MenuItem value="BlogPosts-Sharing">BlogPosts-Sharing</MenuItem>
            <MenuItem value="Classified-Submission">Classified-Submission</MenuItem>
            <MenuItem value="Facebook-Post">Facebook-Post</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Title"
            name="title"
            fullWidth
            variant="outlined"
            value={formData.title}
            onChange={handleChange}
          />
        </Grid>
        
      </Grid>
      <DialogActions>
        <Button onClick={onClose} color="secondary">Cancel</Button>
        <Button onClick={handleSubmit} color="primary">Save</Button>
      </DialogActions>
    </Box>
  );
};

export default CredentialsForm;
