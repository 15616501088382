import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Grid,
  CardMedia,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Chip,
  IconButton,
  TextField,
} from "@mui/material";
import dhlogo from "../assets/dhlogo.jpg";
import axios from "axios";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseIcon from "@mui/icons-material/Close";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// Slugify function to format project name
function slugify(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w\-]+/g, "") // Remove all non-word characters
    .replace(/\-\-+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
}

const SendReportForm = ({ open, onClose, projectId, project }) => {
  const [reportFiles, setReportFiles] = useState([]);
  const [selectedReports, setSelectedReports] = useState([]);
  const [subject, setSubject] = useState("SEO Report");
  const [signature, setSignature] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [additionalFiles, setAdditionalFiles] = useState([]);

  useEffect(() => {
    console.log("useEffect triggered");
    console.log("Project data:", project);

    if (project) {
      const projectData = project.acf_fields || {};
      setCompanyName(projectData.project_name || "");
      setContactEmail(projectData.email || "");
      setSignature(
        `Dear Sir/Madam,\n\nPlease find attached SEO Progress Report for ${projectData.website_url}\n\nNext Report will be sent on 01/09/2024.\n\nRegards,\nSEO Team`
      );
    }

    
    const authToken = localStorage.getItem("token"); // Retrieve the token from localStorage

    if (projectId && authToken) {
      const slugifiedProjectName = slugify(project.acf_fields?.project_name);
      console.log("Slugified Project Name:", slugifiedProjectName);

      const url = `https://seo.digitalhive.in/seo/wp-json/custom/v1/reports/${slugifiedProjectName}`;
      console.log("Fetching from URL:", url);

      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${authToken}`, // Include the token in the request headers
          },
        })
        .then((response) => {
          console.log("API Response:", response);
          if (response.data && response.data.length > 0) {
            const cleanedFiles = response.data.map((file) => ({
              name: file.split("/").pop(),
              fullPath: file,
            }));
            console.log("Cleaned Files:", cleanedFiles);
            setReportFiles(cleanedFiles);
          } else {
            console.warn("No reports found");
          }
        })
        .catch((error) => {
          console.error("Error fetching report files:", error);
        });
    } else {
      console.warn("Missing projectId or authToken");
    }
  }, [project, projectId]);

  const handleReportChange = (event) => {
    setSelectedReports(event.target.value);
  };

  const handleAdditionalFileChange = (event) => {
    const files = Array.from(event.target.files);
    setAdditionalFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const handleRemoveSelectedReport = (reportName) => {
    setSelectedReports((prevReports) =>
      prevReports.filter((r) => r !== reportName)
    );
  };

  const handleRemoveAdditionalFile = (fileName) => {
    setAdditionalFiles((prevFiles) =>
      prevFiles.filter((file) => file.name !== fileName)
    );
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      onClose();
    }
  };

  const handleSendReport = () => {
    const filesToSend = selectedReports
      .map((reportName) => {
        const file = reportFiles.find((r) => r.name === reportName);
        return file ? file.fullPath : null;
      })
      .filter(Boolean);
  
    const formData = new FormData();
    formData.append('email', contactEmail);
    formData.append('subject', subject);
    formData.append('signature', signature);
  
    filesToSend.forEach((file, index) => {
      formData.append(`reportFiles[${index}]`, file);
    });
  
    additionalFiles.forEach((file, index) => {
      const reader = new FileReader();
      reader.onload = () => {
        formData.append(`additionalFiles[${index}]`, {
          name: file.name,
          data: reader.result.split(',')[1] // Extract base64 data from reader.result
        });
      };
      reader.readAsDataURL(file); // Read file as base64 string
    });
  
    // Send the report via the REST API
    axios
      .post('https://seo.digitalhive.in/seo/wp-json/send-report/v1/send', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        console.log('Report sent successfully:', response.data);
        // Notify user of success
      })
      .catch((error) => {
        console.error('Error sending report:', error);
        // Notify user of error
      });
  
    onClose();
  };
  

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      disableEscapeKeyDown
      BackdropProps={{ invisible: true }}
    >
      <DialogTitle>Send Report</DialogTitle>
      <DialogContent>
        <Box component="form" sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CardMedia
                component="img"
                height="150"
                alt="Digital Hive Logo"
                sx={{ objectFit: "contain" }}
                src={dhlogo}
              />
              <TextField
                label="Company Name"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Website URL"
                value={project.acf_fields?.website_url || ""}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email"
                value={contactEmail}
                onChange={(e) => setContactEmail(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="select-reports-label">
                  Select Reports
                </InputLabel>
                <Select
                  labelId="select-reports-label"
                  multiple
                  value={selectedReports}
                  onChange={handleReportChange}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={value}
                          onDelete={() => handleRemoveSelectedReport(value)}
                          deleteIcon={<CloseIcon />}
                        />
                      ))}
                    </Box>
                  )}
                >
                  {reportFiles.length > 0 ? (
                    reportFiles.map((file) => (
                      <MenuItem key={file.name} value={file.name}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          {file.name}
                          {selectedReports.includes(file.name) && (
                            <IconButton
                              size="small"
                              onClick={() =>
                                handleRemoveSelectedReport(file.name)
                              }
                            >
                              <CloseIcon />
                            </IconButton>
                          )}
                        </Box>
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No reports available</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                component="label"
                startIcon={<AttachFileIcon />}
              >
                Attach Additional Files
                <input
                  type="file"
                  multiple
                  hidden
                  onChange={handleAdditionalFileChange}
                />
              </Button>
              <Box sx={{ mt: 1 }}>
                {additionalFiles.map((file, index) => (
                  <Chip
                    key={index}
                    label={file.name}
                    onDelete={() => handleRemoveAdditionalFile(file.name)}
                    deleteIcon={<CloseIcon />}
                    sx={{ mr: 1, mb: 1 }}
                  />
                ))}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <CKEditor
                editor={ClassicEditor}
                data={signature}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setSignature(data);
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSendReport} color="primary">
          Send Report
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SendReportForm;
