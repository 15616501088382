import React from 'react';
import {
    CardContent,
    Typography,
    Box,
    Divider,
    CardMedia
} from "@mui/material";

function ClientProjectDetails({ project }) {
  if (!project) {
    return <Typography>Loading project details...</Typography>;
  }

  const {
    title: { rendered: projectTitle } = { rendered: "N/A" },
    acf_fields: {
      project_name = "n/a",
      date_added="n/a",
      status = "N/A",
      short_bio = " ",
      images: { image1 } = {},
    } = {} // Default to an empty object if acf_fields is undefined
  } = project;

  return (
    <Box sx={{ maxWidth: 800, margin: 'auto', mt: 4, p: 2 }}>
      <CardContent>
      <CardMedia
        component="img"
        height="150"
        alt={image1.alt || "Project Image"}
        sx={{ objectFit: "contain" }}
        src={image1.url} // Fallback to default image
      />
        <Typography variant="h5" component="div" gutterBottom>
          {project_name}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2
          }}
        >
          <Typography variant="subtitle1" color="textSecondary">
            Status:{" "}
            <Typography component="span" color="success.main">
              {status}
            </Typography>
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            Start Date:{" "}
            <Typography component="span" color="success.main">
              {date_added}
            </Typography>
          </Typography>
        </Box>
        <Divider sx={{ mb: 2 }} />
       
        <Typography variant="body1" color="text.secondary" gutterBottom>
        {short_bio}
        </Typography>
      </CardContent>
    </Box>
  );
}

export default ClientProjectDetails;
