import React from "react";
import {
  CardContent,
  Typography,
  Grid,
  Avatar,
  Link,
  Box,
  Divider,
  IconButton,
  Tooltip
} from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import { toast } from 'react-toastify';

const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text).then(() => {
    toast.success('Text copied to clipboard!', {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }).catch((err) => {
    toast.error('Failed to copy text: ' + err, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  });
};

const ProjectDetailsCard = ({ project, assignedUsers }) => {
  if (!project) return null;

  const {
    title: { rendered: projectTitle } = { rendered: "N/A" },
    acf_fields: {
      project_name="n/a",
      status = "N/A",
      date_added = "N/A",
      address = "N/A",
      phone_number = "N/A",
      email = "N/A",
      gmail_id = "N/A",
      password = "N/A",
      category = "N/A",
      facebook = "",
      twitter = "",
      linkedin = "",
      instagram = "",
      website="",
    } = {} // Default to an empty object if acf_fields is undefined
  } = project;

  return (
    <Box sx={{ maxWidth: 800, margin: 'auto', mt: 4, p: 2 }}>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          {project_name}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2
          }}
        >
          <Typography variant="subtitle1" color="textSecondary">
            Status:{" "}
            <Typography component="span" color="success.main">
              {status}
            </Typography>
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            Start Date:{" "}
            <Typography component="span" color="success.main">
              {date_added}
            </Typography>
          </Typography>
        </Box>
        <Divider sx={{ mb: 2 }} />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box>
             
              <Typography variant="body1" color="text.secondary" gutterBottom>
                {projectTitle}
              </Typography>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6" gutterBottom>
                  EmailId:
                </Typography>
                <Tooltip title="Copy Email Info">
                  <IconButton onClick={() => copyToClipboard(email)}>
                    <ContentCopyIcon fontSize="small" color="primary" />
                  </IconButton>
                </Tooltip>
              </Box>
              <Typography variant="body1" color="text.secondary" gutterBottom>
                {email}
              </Typography>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6" gutterBottom>
                  Website:
                </Typography>
                <Tooltip title="Copy Website Url">
                  <IconButton onClick={() => copyToClipboard(website)}>
                    <ContentCopyIcon fontSize="small" color="primary" />
                  </IconButton>
                </Tooltip>
              </Box>
                <Typography variant="body1" color="text.secondary" gutterBottom>
               {website}
              </Typography>
            
              <Typography variant="h6" gutterBottom>Social Media:</Typography>
              <Box>
                {facebook && (
                  <IconButton color="primary" href={facebook} target="_blank">
                    <FacebookIcon />
                  </IconButton>
                )}
                {twitter && (
                  <IconButton color="primary" href={twitter} target="_blank">
                    <TwitterIcon />
                  </IconButton>
                )}
                {linkedin && (
                  <IconButton color="primary" href={linkedin} target="_blank">
                    <LinkedInIcon />
                  </IconButton>
                )}
                {instagram && (
                  <IconButton color="primary" href={instagram} target="_blank">
                    <InstagramIcon />
                  </IconButton>
                )}
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6" gutterBottom>
                  Address:
                </Typography>
                <Tooltip title="Copy Address">
                  <IconButton onClick={() => copyToClipboard(address)}>
                    <ContentCopyIcon fontSize="small" color="primary" />
                  </IconButton>
                </Tooltip>
              </Box>
              <Typography variant="body1" color="text.secondary" gutterBottom>
                {address}
              </Typography>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6" gutterBottom>
                  Phone Number:
                </Typography>
                <Tooltip title="Copy Phone Number">
                  <IconButton onClick={() => copyToClipboard(phone_number)}>
                    <ContentCopyIcon fontSize="small" color="primary" />
                  </IconButton>
                </Tooltip>
              </Box>
              <Typography variant="body1" color="text.secondary" gutterBottom>
                {phone_number}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6" gutterBottom>
                  GMId:
                </Typography>
                <Tooltip title="Copy GMId">
                  <IconButton onClick={() => copyToClipboard(gmail_id)}>
                    <ContentCopyIcon fontSize="small" color="primary" />
                  </IconButton>
                </Tooltip>
              </Box>
              <Typography variant="body1" color="text.secondary" gutterBottom>
                {gmail_id}
              </Typography>
              <Typography variant="h6" gutterBottom>
                GM Pwd:
              </Typography>
              <Typography variant="body1" color="text.secondary" gutterBottom>{password}</Typography>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6" gutterBottom>
                  Category:
                </Typography>
                <Tooltip title="Copy Category">
                  <IconButton onClick={() => copyToClipboard(category)}>
                    <ContentCopyIcon fontSize="small" color="primary" />
                  </IconButton>
                </Tooltip>
              </Box>
              <Typography variant="body1" color="text.secondary" gutterBottom>
                {category}
              </Typography>
              <Typography variant="h6" gutterBottom>
                Assigned Users:
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                {assignedUsers.map((user, index) => (
                  <Tooltip key={index} title={user.display_name}>
                    <Avatar
                      alt={user.display_name}
                      sx={{ width: 32, height: 32 }}
                    >
                      {user.display_name.charAt(0)}
                    </Avatar>
                  </Tooltip>
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Box>
  );
};

export default ProjectDetailsCard;
