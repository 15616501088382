import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  TextField,
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  pdf,
  Font,
} from "@react-pdf/renderer";
import arrowUp from "../assets/arrow-up.png";
import arrowForward from "../assets/arrow-forward.png";
import arrowDown from "../assets/arrow-down.png";
import logo from "../assets/dhlogo.jpg";
import { Image } from "@react-pdf/renderer";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFWp0e.ttf",
    },
    {
      src: "https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN_r8OXOhs.ttf",
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontFamily: "Open Sans",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  logo: {
    width: 80,
    height: 80,
  },
  title: {
    fontSize: 18,
    textAlign: "center",
    color: "#333",
    marginBottom: 5,
  },
  dateRange: {
    fontSize: 10,
    textAlign: "center",
    color: "#777",
    marginBottom: 15,
  },
  table: {
    display: "table",
    width: "auto",
    marginTop: 10,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#BDBDBD",
    borderRadius: 5,
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "#E0E0E0",
  },
  tableHeaderRow: {
    flexDirection: "row",
    backgroundColor: "#2B547E", // Darker blue color
    color: "#FFFFFF",
    borderBottomWidth: 2,
    borderColor: "#BDBDBD",
  },
  keywordCol: {
    width: "28%", // Make the keyword column wider
    padding: 8,
  },
  tableCol: {
    width: "12%", // Other columns share the remaining width
    padding: 8,
  },
  tableCell: {
    fontSize: 10,
    textAlign: "center",
  },
  tableHeaderCell: {
    fontSize: 10,
    fontWeight: "bold",
    color: "#FFFFFF",
    textAlign: "center",
  },
  evenRow: {
    backgroundColor: "#F5F5F5",
  },
  oddRow: {
    backgroundColor: "#FFFFFF",
  },
  legend: {
    marginTop: 20,
    fontSize: 10,
  },
  legendItem: {
    marginBottom: 5,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    width: 12,
    height: 12,
    marginRight: 5,
  },
  tableSubHeaderRow: {
    flexDirection: "row",
    backgroundColor: "#4A90E2",
  },

  secondPageTitle: {
    fontSize: 16,
    textAlign: "center",
    marginBottom: 10,
    color: "#2B547E",
    fontWeight: "bold",
  },
  secondPageText: {
    fontSize: 12,
    lineHeight: 1.5, // Increase line-height for better readability
    color: "#333",
    textAlign: "justify",
    textIndent: 30, // Add text indent for paragraph start
    marginHorizontal: 15, // Ensure there is horizontal space
    marginTop: 10, // Spacing from the title
    marginBottom: 10, // Space at the bottom
    overflow: "hidden", // Handle text overflowing the page area
  },
  bulletPoints: {
    fontSize: 12,
    lineHeight: 1.5,
    color: "#333",
    textAlign: "justify",
    marginBottom: 5,
    marginLeft: 20,
  },
  boldText: {
    fontWeight: "bold",
  },
  textBlock: {
    fontSize: 12,
    lineHeight: 1.5,
    color: "#333",
    textAlign: "justify",
    marginBottom: 5,
  },
  borderRight: {
    borderRightWidth: 2,  // Set the width of the right border
    borderColor: "#FFFFFF",  // Set the color of the border
  },
});
const getArrow = (val1, val2, isPdf = false) => {
  const prev = parseInt(val1, 10);
  const curr = parseInt(val2, 10);

  if (isNaN(prev) || isNaN(curr)) return null;

  if (curr < prev) {
    return isPdf ? arrowUp : <ArrowUpwardIcon style={{ color: "green" }} />;
  }
  if (curr > prev) {
    return isPdf ? arrowDown : <ArrowDownwardIcon style={{ color: "red" }} />;
  }

  return isPdf ? arrowForward : <ArrowForwardIcon style={{ color: "grey" }} />;
};

const ReportPDF = ({ data, projectName, dateRange }) => (
  <Document>
    <Page style={styles.page}>
      <View style={styles.header}>
        <Image style={styles.logo} src={logo} />
      </View>
      <Text style={styles.title}>
        Keyword Comparison Report for {projectName}
      </Text>
      <Text style={styles.dateRange}>{`Comparison Dates: ${dateRange}`}</Text>
      <View style={styles.table}>
        <View style={styles.tableSubHeaderRow}>
          <View style={[styles.tableHeaderCol, { flex: 1 }]} />
          <View style={[styles.tableHeaderCol, { flex: 3 }]}>
            <Text style={styles.tableHeaderCell}>Google Maps Rankings</Text>
          </View>
          <View style={[styles.tableHeaderCol, { flex: 3 } ]}>
            <Text style={styles.tableHeaderCell}>Google Search Rankings</Text>
          </View>
        </View>
        <View style={styles.tableHeaderRow}>
          <View style={styles.keywordCol}>
            <Text style={styles.tableHeaderCell}>Keyword</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableHeaderCell}>Previous Listing</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableHeaderCell}>Current Listing</Text>
          </View>
          <View style={[styles.tableCol, styles.borderRight]}>
            <Text style={styles.tableHeaderCell}>Listing Change</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableHeaderCell}>Previous Rank</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableHeaderCell}>Current Rank</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableHeaderCell}>Rank Change</Text>
          </View>
        </View>
        {data.map((item, index) => {
          const rowStyle = index % 2 === 0 ? styles.evenRow : styles.oddRow;
          const rankArrow = getArrow(item.rank1, item.rank2, true);
          const listingArrow = getArrow(
            item.listingVal1,
            item.listingVal2,
            true
          );

          return (
            <View style={[styles.tableRow, rowStyle]} key={index}>
              <View style={styles.keywordCol}>
                <Text style={styles.tableCell}>{item.keyword}</Text>
              </View>

              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {item.listingVal1 !== undefined ? item.listingVal1 : "-"}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {item.listingVal2 !== undefined ? item.listingVal2 : "-"}
                </Text>
              </View>
              <View style={styles.tableCol}>
                {listingArrow && (
                  <Image src={listingArrow} style={styles.icon} />
                )}
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {item.rank1 !== undefined ? item.rank1 : "-"}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {item.rank2 !== undefined ? item.rank2 : "-"}
                </Text>
              </View>
              <View style={styles.tableCol}>
                {rankArrow && <Image src={rankArrow} style={styles.icon} />}
              </View>
            </View>
          );
        })}
      </View>
      <View style={styles.legend}>
        <Text style={{ fontWeight: "bold" }}>Legend:</Text>
        <View style={styles.legendItem}>
          <Image src={arrowUp} style={styles.icon} />
          <Text>Indicates an improvement in the ranking or listing.</Text>
        </View>
        <View style={styles.legendItem}>
          <Image src={arrowDown} style={styles.icon} />
          <Text>Indicates a decline in the ranking or listing.</Text>
        </View>
        <View style={styles.legendItem}>
          <Image src={arrowForward} style={styles.icon} />
          <Text>Indicates no change in the ranking or listing.</Text>
        </View>
      </View>
    </Page>
    <Page style={styles.page}>
      <Text style={styles.textBlock}>
        In an SEO report, "Google rank" refers to the position at which a
        website appears in Google's search engine results pages (SERPs) for a
        particular keyword or search query. It is a key performance indicator
        (KPI) used to evaluate the effectiveness of a website’s SEO efforts.
      </Text>
      <Text style={styles.textBlock}>
        <Text style={styles.boldText}>For example:</Text>
      </Text>
      <Text style={styles.bulletPoints}>
        Position 1 means the website is at the top of the first page of Google
        search results for that query.
      </Text>
      <Text style={styles.bulletPoints}>
        Position 5 indicates the website appears as the fifth result.
      </Text>
      <Text style={styles.bulletPoints}>
        Position 11 typically means the website is on the second page, as each
        page usually has 10 results.
      </Text>
      <Text style={styles.textBlock}>
        The ranking can fluctuate based on various factors, such as algorithm
        updates, competition, and content quality. An SEO report tracks the
        rankings over time to measure improvement or changes in visibility for
        targeted keywords.
      </Text>
      <Text style={styles.textBlock}>
        The report shows fluctuations in keyword rankings:
      </Text>
      <Text style={styles.bulletPoints}>
        Column 1 (Keyword) shows the list of keywords.
      </Text>
      <Text style={styles.bulletPoints}>
        Column 2 (Previous Listing) shows what position the website was showing
        up on Google Maps in the Previous Report.
      </Text>
      <Text style={styles.bulletPoints}>
        Column 3 (Current Listing) shows what position the website is showing up
        on Google Maps at the time of generation of the Current Report.
      </Text>
      <Text style={styles.bulletPoints}>
        Column 4 (Listing Change) is illustrated in the Legend above.
      </Text>
      <Text style={styles.bulletPoints}>
        Column 5 (Previous Ranking) shows what position the website was showing
        up in Google Rankings in the Previous Report.
      </Text>
      <Text style={styles.bulletPoints}>
        Column 6 (Current Ranking) shows what position the website is showing up
        in Google Rankings at the time of generation of the Current Report.
      </Text>
      <Text style={styles.bulletPoints}>
        Column 7 (Rank Change) is illustrated in the Legend above.
      </Text>
    </Page>
  </Document>
);

const ReportModal = ({ open, onClose, projectId, project }) => {
  const { acf_fields: { project_name } = {} } = project || {};

  const [date1, setDate1] = useState(new Date());
  const [date2, setDate2] = useState(new Date());
  const [comparisonData, setComparisonData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");

  const fetchComparisonData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://seo.digitalhive.in/seo/wp-json/custom/v1/keywords_comparison/${projectId}`,
        {
          params: {
            date1: date1.toISOString().split("T")[0],
            date2: date2.toISOString().split("T")[0],
          },
        }
      );
      setComparisonData(response.data);
      setLoading(false);
    } catch (error) {
      console.error(`Error fetching keyword comparison:`, error);
      toast.error("Failed to fetch keyword comparison");
      setLoading(false);
    }
  };
  const generatePDFAndUpload = async () => {
    // Format the date range for the filename
    const formattedDateRange = `${date1
      .toLocaleDateString("en-GB")
      .replace(/\//g, "_")}_to_${date2
      .toLocaleDateString("en-GB")
      .replace(/\//g, "_")}`;

    // Generate the filename using the project name and date range
    const fileName = `${project_name}_${formattedDateRange}.pdf`;

    const blob = await pdf(
      <ReportPDF
        data={comparisonData}
        projectName={project_name}
        dateRange={`Previous Date:${date1.toLocaleDateString()} - Current Date:${date2.toLocaleDateString()}`}
      />
    ).toBlob();

    uploadPDF(blob, fileName); // Pass the generated filename to the uploadPDF function
  };

  const uploadPDF = async (pdfBlob, fileName) => {
    const formData = new FormData();
    formData.append("file", pdfBlob, fileName);

    try {
      const response = await axios.post(
        `https://seo.digitalhive.in/seo/wp-json/custom/v1/upload_pdf?project_name=${encodeURIComponent(
          project_name
        )}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setPdfUrl(response.data.url);
      toast.success("PDF generated and uploaded successfully");
    } catch (error) {
      console.error("Error uploading PDF:", error);
      toast.error("Failed to upload PDF");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Generate Report For: {project_name}</DialogTitle>
      <DialogContent>
        <Box component="form" sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">Previous Rank</Typography>
              <DatePicker
                selected={date1}
                onChange={(date) => setDate1(date)}
                customInput={<TextField fullWidth />}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">Latest Rank</Typography>
              <DatePicker
                selected={date2}
                onChange={(date) => setDate2(date)}
                customInput={<TextField fullWidth />}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={fetchComparisonData}
                disabled={loading}
              >
                Generate Report
              </Button>
            </Grid>
            {comparisonData.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="h6" component="div" gutterBottom>
                  Report
                </Typography>
                {loading ? (
                  <Typography>Loading...</Typography>
                ) : (
                  <TableContainer component={Paper}>
                    <Table id="report-content">
                      <TableHead>
                        <TableRow>
                          <TableCell>Keywords</TableCell>
                          <TableCell align="right">Previous Rank</TableCell>
                          <TableCell align="right">Latest Rank</TableCell>
                          <TableCell align="right">Rank Change</TableCell>
                          <TableCell align="right">Previous Listing</TableCell>
                          <TableCell align="right">Latest Listing</TableCell>
                          <TableCell align="right">Listing Change</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {comparisonData.map((data, index) => {
                          const rankArrow = getArrow(data.rank1, data.rank2);
                          const listingArrow = getArrow(
                            data.listingVal1,
                            data.listingVal2
                          );

                          return (
                            <TableRow key={index}>
                              <TableCell>{data.keyword}</TableCell>
                              <TableCell align="right">
                                {data.rank1 || "-"}
                              </TableCell>
                              <TableCell align="right">
                                {data.rank2 || "-"}
                              </TableCell>
                              <TableCell align="right">{rankArrow}</TableCell>
                              <TableCell align="right">
                                {data.listingVal1 || "-"}
                              </TableCell>
                              <TableCell align="right">
                                {data.listingVal2 || "-"}
                              </TableCell>
                              <TableCell align="right">
                                {listingArrow}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Grid>
            )}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
        <Button onClick={generatePDFAndUpload} color="primary">
          Generate and Upload PDF
        </Button>
        {pdfUrl && (
          <Button component="a" href={pdfUrl} target="_blank" color="primary">
            View PDF
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ReportModal;
