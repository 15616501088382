import React, { useState } from "react";
import {
  Typography,
  Box,
  Grid,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TablePagination,
  Paper,
} from "@mui/material";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";

const WorkView = ({ workData }) => {
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const getMonths = () => {
    if (!workData) return [];
    const months = new Set();
    Object.keys(workData).forEach((activityType) => {
      workData[activityType].forEach((item) => {
        const monthYear = new Date(item.date).toLocaleString("default", {
          month: "long",
          year: "numeric",
        });
        months.add(monthYear);
      });
    });
    return Array.from(months);
  };

  const getCategoriesForMonth = (month) => {
    const categories = {};
    Object.keys(workData).forEach((category) => {
      const filteredItems = workData[category].filter(
        (item) =>
          new Date(item.date).toLocaleString("default", {
            month: "long",
            year: "numeric",
          }) === month
      );
      if (filteredItems.length > 0) {
        categories[category] = filteredItems;
      }
    });
    return categories;
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (selectedCategory) {
    const filteredData = workData[selectedCategory].filter(
      (item) =>
        new Date(item.date).toLocaleString("default", {
          month: "long",
          year: "numeric",
        }) === selectedMonth
    );

    return (
      <Box sx={{ width: "100%", maxWidth: "1200px", mx: "auto", pt: 4 }}>
        <Button
          onClick={() => setSelectedCategory(null)}
          sx={{ mb: 2 }}
          variant="contained"
        >
          Back to Category Selection
        </Button>
        <Typography variant="h5" sx={{ mt: 4 }}>
          {selectedCategory} Work Details for {selectedMonth}
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Link</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((workItem, index) => (
                  <TableRow key={index}>
                    <TableCell>{workItem.title}</TableCell>
                    <TableCell>{workItem.work_description}</TableCell>
                    <TableCell>
                      <a
                        href={workItem.landing_page}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {workItem.landing_page}
                      </a>
                    </TableCell>
                    <TableCell>
                      {new Date(workItem.date).toLocaleDateString()}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </TableContainer>
      </Box>
    );
  }

  if (selectedMonth) {
    const categoriesForMonth = getCategoriesForMonth(selectedMonth);
    return (
      <Box sx={{ width: "100%", maxWidth: "1200px", mx: "auto", pt: 4 }}>
        <Button
          onClick={() => setSelectedMonth(null)}
          sx={{ mb: 2 }}
          variant="contained"
        >
          Back to Month Selection
        </Button>
        <Typography variant="h5" sx={{ mt: 4 }}>
          Select a Category for {selectedMonth}
        </Typography>
        <Grid container spacing={2}>
          {Object.keys(categoriesForMonth).map((category, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card variant="outlined">
                <CardActionArea onClick={() => setSelectedCategory(category)}>
                  <CardContent>
                    <Avatar sx={{ bgcolor: "primary.main" }}>
                      <WorkOutlineIcon />
                    </Avatar>
                    <Typography variant="h6">
                      {category.replace(/-/g, " ")}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h5" sx={{ mt: 4 }}>
        Select a Month
      </Typography>
      <Grid container spacing={2}>
        {getMonths().map((month, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => setSelectedMonth(month)}
            >
              {month}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default WorkView;
