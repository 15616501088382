import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Grid,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  Button,
  Avatar,
} from "@mui/material";
import {
  WorkOutline,
  InsertDriveFile as ReportIcon,
} from "@mui/icons-material";

import ClientProjectDetails from "../components/ClientProjectDetails";
import WorkView from "../components/WorkView"; 
import ReportsView from "../components/ReportsView";

const WorkDoneForSubscriber = () => {
  const [project, setProject] = useState(null);
  const [workData, setWorkData] = useState({});
  const [selectedView, setSelectedView] = useState(""); // "work" or "reports"
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        // Fetching initial work data to obtain the project ID
        const workResponse = await axios.get(
          `https://seo.digitalhive.in/seo/wp-json/custom/v1/work-done-subscriber`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        
        setWorkData(workResponse.data);
        const projectId = workResponse.data['Classified-Submission'][0].project_id;
        const projectResponse = await axios.get(
          `https://seo.digitalhive.in/seo/wp-json/custom/v1/projects/${projectId}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setProject(projectResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchInitialData();
  }, []);

  return (
    <Box sx={{ width: "100%", maxWidth: "1200px", mx: "auto", pt: 4 }}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={12}>
          <ClientProjectDetails project={project} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Card variant="outlined" sx={{ textAlign: 'center', border: selectedView === 'work' ? '2px solid #2196f3' : 'none' }}>
            <CardActionArea onClick={() => setSelectedView("work")}>
              <CardContent>
                <Avatar sx={{ m: 'auto', bgcolor: "primary.main" }}>
                  <WorkOutline />
                </Avatar>
                <Typography variant="h6">View Work</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card variant="outlined" sx={{ textAlign: 'center', border: selectedView === 'reports' ? '2px solid #2196f3' : 'none' }}>
            <CardActionArea onClick={() => setSelectedView("reports")}>
              <CardContent>
                <Avatar sx={{ m: 'auto', bgcolor: "primary.main" }}>
                  <ReportIcon />
                </Avatar>
                <Typography variant="h6">View Reports</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>

      {/* Render based on selection */}
      {selectedView === "work" && <WorkView workData={workData} />}
      {selectedView === "reports" && <ReportsView projectId={project?.id} project={project}/>}
    </Box>
  );
};

export default WorkDoneForSubscriber;
