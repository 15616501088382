import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Grid, Box, Typography, IconButton, Tooltip, InputAdornment } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import axios from 'axios';
import { toast } from 'react-toastify';

const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text).then(() => {
    toast.success('Text copied to clipboard!', {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }).catch((err) => {
    toast.error('Failed to copy text: ' + err, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  });
};

const KeywordsRankDialog = ({ open, onClose, projectId }) => {
  const [keywords, setKeywords] = useState([]);
  const [rankData, setRankData] = useState([]);
  const [reportDate, setReportDate] = useState('');

  useEffect(() => {
    if (projectId && open) {
      axios.get(`https://seo.digitalhive.in/seo/wp-json/custom/v1/keywords/${projectId}`)
        .then(response => {
          setKeywords(response.data);
          setRankData(response.data.map(item => ({ kid: item.kid, keyword: item.keyword, rankVal: item.rankVal, listingVal: item.listingVal })));
        })
        .catch(error => {
          console.error('Error fetching project keywords:', error);
        });
    }
  }, [projectId, open]);

  const handleInputChange = (index, field, value) => {
    const newRankData = [...rankData];
    newRankData[index][field] = value;
    setRankData(newRankData);
  };

  const handleSave = () => {
    rankData.forEach(data => {
      axios.post('https://seo.digitalhive.in/seo/wp-json/custom/v1/keywords', {
        kid: data.kid,
        rankVal: data.rankVal,
        listingVal: data.listingVal,
        reportDate: reportDate // Adding the report date here
      }).then(response => {
        console.log('Rank data saved:', response.data);
      }).catch(error => {
        console.error('Error saving rank data:', error);
      });
    });
    onClose();
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      onClose();
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={handleClose} 
      maxWidth="lg" 
      fullWidth
      disableEscapeKeyDown
      BackdropProps={{ invisible: true }}
      sx={{ '& .MuiDialog-paper': { width: '80%', maxWidth: 'none' } }}
    >
      <DialogTitle>Keywords Rank</DialogTitle>
      <DialogContent>
        <Box component="form" sx={{ mt: 3 }}>
          <Typography variant="h6" component="div" gutterBottom>
            Keywords Rank
          </Typography>
          <TextField
            label="Report Date"
            type="date"
            fullWidth
            value={reportDate}
            onChange={(e) => setReportDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ mb: 2 }}
          />
          <Grid container spacing={2}>
            {keywords.map((keywordData, index) => (
              <React.Fragment key={index}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Keyword"
                    value={keywordData.keyword}
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title="Copy to clipboard">
                            <IconButton onClick={() => copyToClipboard(keywordData.keyword)}>
                              <ContentCopyIcon fontSize='small' color='primary'/>
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Rank"
                    value={rankData[index]?.rankVal || ''}
                    onChange={(e) => handleInputChange(index, 'rankVal', e.target.value)}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Local Listing"
                    value={rankData[index]?.listingVal || ''}
                    onChange={(e) => handleInputChange(index, 'listingVal', e.target.value)}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">Cancel</Button>
        <Button onClick={handleSave} color="primary">Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default KeywordsRankDialog;
