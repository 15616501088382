import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  Typography,
  Card,
  CardContent,
  CardActions,
  TextField,
  ListItemText
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import axios from "axios";
import { toast } from "react-toastify";

const CompletedTasksDialog = ({ open, onClose, taskData }) => {
  const [editIndex, setEditIndex] = useState(null);
  const [editFormData, setEditFormData] = useState({
    id: "",
    activity_url: "",
    title: "",
    tag: "",
    landing_page: "",
    work_description: "",
    activity_type: "",
    user: "",
  });

  // Filter tasks by the current month and year
  const currentMonthTasks = taskData.filter(task => {
    const taskDate = new Date(task.date); // Assuming the date is in a format that Date can parse
    const currentDate = new Date();
    console.log(`Task Date: ${taskDate}, Current Date: ${currentDate}`);
    return (
      taskDate.getMonth() === currentDate.getMonth() &&
      taskDate.getFullYear() === currentDate.getFullYear()
    );
  });

  console.log('Filtered Tasks:', currentMonthTasks);

  const handleEditClick = (index) => {
    setEditIndex(index);
    setEditFormData(currentMonthTasks[index]);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditFormData({ ...editFormData, [name]: value });
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(
        `https://seo.digitalhive.in/seo/wp-json/custom/v1/works/${editFormData.id}`,
        editFormData
      );
      toast.success("Work updated successfully");
      setEditIndex(null);
      onClose(); // Refresh the data by closing the dialog
    } catch (error) {
      toast.error("Failed to update work");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Completed Tasks</DialogTitle>
      <DialogContent>
        <List>
          {currentMonthTasks.length === 0 ? (
            <Typography variant="body2" color="textSecondary">
              No completed tasks available for this month.
            </Typography>
          ) : (
            currentMonthTasks.map((task, index) => (
              <ListItem key={index} divider>
                {editIndex === index ? (
                  <Card variant="outlined" sx={{ mb: 2, p: 2 }}>
                    <form onSubmit={handleEditSubmit}>
                      <CardContent>
                        <TextField
                          name="activity_url"
                          label="Activity URL"
                          fullWidth
                          value={editFormData.activity_url}
                          onChange={handleEditChange}
                          required
                          sx={{ mb: 2 }}
                        />
                        <TextField
                          name="title"
                          label="Title"
                          fullWidth
                          value={editFormData.title}
                          onChange={handleEditChange}
                          required
                          sx={{ mb: 2 }}
                        />
                        <TextField
                          name="tag"
                          label="Tag"
                          fullWidth
                          value={editFormData.tag}
                          onChange={handleEditChange}
                          required
                          sx={{ mb: 2 }}
                        />
                        <TextField
                          name="landing_page"
                          label="Landing Page"
                          fullWidth
                          value={editFormData.landing_page}
                          onChange={handleEditChange}
                          required
                          sx={{ mb: 2 }}
                        />
                        <TextField
                          name="work_description"
                          label="Work Description"
                          fullWidth
                          multiline
                          rows={4}
                          value={editFormData.work_description}
                          onChange={handleEditChange}
                          required
                          sx={{ mb: 2 }}
                        />
                      </CardContent>
                      <CardActions>
                        <Button
                          onClick={() => setEditIndex(null)}
                          color="secondary"
                          startIcon={<CancelOutlinedIcon />}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          color="primary"
                          startIcon={<SaveIcon />}
                        >
                          Save
                        </Button>
                      </CardActions>
                    </form>
                  </Card>
                ) : (
                  <>
                    <ListItemText
                      primary={
                        <Typography variant="body1">
                          {task.activity_url}
                        </Typography>
                      }
                      secondary={
                        <>
                          <Typography variant="body2">
                            Title: {task.title}
                          </Typography>
                          <Typography variant="body2">
                            Tag: {task.tag}
                          </Typography>
                          <Typography variant="body2">
                            Landing Page: {task.landing_page}
                          </Typography>
                          <Typography variant="body2">
                            Description: {task.work_description}
                          </Typography>
                          <Typography variant="body2">
                            Activity Type: {task.activity_type}
                          </Typography>
                          <Typography variant="body2">
                            User: {task.user}
                          </Typography>
                        </>
                      }
                    />
                    <Button
                      onClick={() => handleEditClick(index)}
                      color="primary"
                      startIcon={<EditIcon />}
                    >
                      Edit
                    </Button>
                  </>
                )}
              </ListItem>
            ))
          )}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CompletedTasksDialog;
