import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { ThemeProvider, CssBaseline } from "@mui/material";
import theme from "./theme";
import ProjectDashboard from "./pages/ProjectDashboard";
import CreateProjectForm from "./components/CreateProjectForm";
import ProjectList from "./components/ProjectList";
import LoginPage from "./pages/LoginPage";
import Header from "./components/Header";
import UserProfile from "./pages/UserProfile";
import WorkDoneForSubscriber from "./pages/WorkDoneForSubscriber";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);
      if (decoded.exp * 1000 > Date.now()) {
        fetchCurrentUser(token);
      } else {
        localStorage.removeItem("token");
        setLoggedIn(false);
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    if (currentUser?.roles?.includes("subscriber")) {
      navigate("/limitedaccess");
    }
  }, [currentUser, navigate]);

  const fetchCurrentUser = async (token) => {
    try {
      const response = await axios.get("https://seo.digitalhive.in/seo/wp-json/wp/v2/users/me", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setCurrentUser(response.data);
    
      setLoggedIn(true);
    } catch (error) {
      console.error("Error fetching current user:", error);
      toast.error("Failed to fetch current user data");
      setLoggedIn(false);
      navigate("/login");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {loggedIn ? (
        <>
          <Header setLoggedIn={setLoggedIn} currentUser={currentUser} />
          <Routes>
            {currentUser?.roles.includes("subscriber") ? (
              <>
                <Route path="/limitedaccess" element={<WorkDoneForSubscriber />} />
                <Route path="*" element={<Navigate replace to="/limitedaccess" />} />
              </>
            ) : (
              <>
                <Route path="/" element={<ProjectList />} />
                <Route path="/projects/:projectId" element={<ProjectDashboard />} />
                <Route path="/create-project" element={<CreateProjectForm />} />
                <Route path="/profile" element={<UserProfile />} />
                <Route path="*" element={<Navigate replace to="/" />} />
              </>
            )}
          </Routes>
          <ToastContainer />
        </>
      ) : (
        <LoginPage setLoggedIn={setLoggedIn} />
      )}
    </ThemeProvider>
  );
}

export default App;
